import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

export const headerTitleSelector = createSelector(
  (state: RootState) => state.config,
  (config) => ({
    headerTitle: config.headerTitle,
    headerTitleBreakLine: config.headerTitleBreakLine,
  })
);
export const deviceSelector = (state: RootState) => state.config.device;
export const screenSelector = (state: RootState) => state.config.screen;
export const counterFlagSelector = (state: RootState) => state.config.counterFlag;
export const jodAdminSelector = (state: RootState) => state.config.jodAdmin;
