import { FC } from 'react';
import { FaRegBell, FaUserTie, FaXmark } from 'react-icons/fa6';
import IconButton from './IconButton';

interface NotificationsProp {
  setOpenNotification: Function;
}

const Notifications: FC<NotificationsProp> = ({ setOpenNotification }) => {
  return (
    <div className="flex fixed bg-black bg-opacity-40 inset-x-0 inset-y-0 flex-row-reverse z-10">
      <div className="flex flex-col bg-vWhite w-full lg:w-1/4 justify-between">
        <div className="flex flex-col w-full p-8 h-full overflow-auto">
          <div className="flex justify-end text-lg">
            <IconButton textColor="text-vGray" onClick={() => setOpenNotification(false)}>
              <FaXmark />
            </IconButton>
          </div>
          <div className="flex flex-col space-y-8">
            <div className="flex w-full border-b-2 border-vOrange justify-center pt-8">
              <div className="flex space-x-2 pb-4 align-center">
                <FaRegBell className="text-vGray text-lg" />
                <p className="text-vOrange text-base font-bold">NOTIFICATIONS</p>
                <div className="text-vOrange bg-vLightOrange px-3 rounded-full">
                  <p>5</p>
                </div>
              </div>
            </div>
            {/* <div className="flex space-x-2 justify-end text-vGray text-sm">
              <button className="flex rounded-full shadow-md ease-in duration-150 hover:shadow-slate-500">
                <p className="py-1 px-2">All Notifications</p>
              </button>
              <button className="flex rounded-full shadow-md ease-in duration-150 hover:shadow-slate-500">
                <p className="py-1 px-2">Unread</p>
              </button>
            </div> */}
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vBlue">
                <div className="flex w-full justify-between">
                  <p className="text-vBlue font-bold">Onboarding Initiated</p>
                  <div className="flex space-x-4 text-vGray text-xs">
                    <p>Mar 12, 2024</p>
                    <p>15:02</p>
                  </div>
                </div>
                <div className="flex w-full justify-between">
                  <div className="flex space-x-4 align-center">
                    <FaUserTie className="text-2xl" />
                    <p>3 Shifts at Seoul Branch requiring approval</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vPink">
                <div className="flex w-full justify-between">
                  <p className="text-vPink font-bold">Task Assignment</p>
                  <div className="flex space-x-4 text-vGray text-xs">
                    <p>Mar 12, 2024</p>
                    <p>15:02</p>
                  </div>
                </div>
                <div className="flex w-full justify-between">
                  <div className="flex space-x-4 align-center">
                    <FaUserTie className="text-2xl" />
                    <p>Create Job Templates for Outlet</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vGreen">
                <div className="flex w-full justify-between">
                  <p className="text-vGreen font-bold">Shift Reminder</p>
                  <div className="flex space-x-4 text-vGray text-xs">
                    <p>Mar 12, 2024</p>
                    <p>15:02</p>
                  </div>
                </div>
                <div className="flex w-full justify-between">
                  <div className="flex space-x-4 align-center">
                    <FaUserTie className="text-2xl" />
                    <p>Shift swap requested for Waiter at Seoul Branch</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vOrange">
                <div className="flex w-full justify-between">
                  <p className="text-vOrange font-bold">Application</p>
                  <div className="flex space-x-4 text-vGray text-xs">
                    <p>Mar 12, 2024</p>
                    <p>15:02</p>
                  </div>
                </div>
                <div className="flex w-full justify-between">
                  <div className="flex space-x-4 align-center">
                    <FaUserTie className="text-2xl" />
                    <p>Shift swap requested for Waiter at Seoul Branch</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
