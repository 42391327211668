import { ENV, PATH_NAME } from 'configs';
import { useCallback, useMemo } from 'react';

// import { assignedLocationSelector } from 'store/modules/location-profile';

// import { Role } from 'models/user';

// import { useCurrentPermission, useCurrentRole } from 'hooks/components/auth';

import { MenuConfig } from '.';
// import { userPermission } from 'pro/config/user';
// import { configActions, headerTitleSelector } from 'store/modules/config';
// import { authStorage, hqStorage, userStorage } from 'configs/browser-storage';
// import { AppDispatch } from 'store/root-reducer';
// import { useDispatch, useSelector } from 'react-redux';
// import { SystemPermission } from 'types';
// import { InternalPermission, PartnerPermission } from 'types';
import { useCurrentFeature } from 'hooks/components/auth/useCurrentUserFeature';
import { useCurrentRoleId } from 'pro/auth/useCurrentRoleId';
import { useCurrentPermission } from 'pro/hooks/useCurrentPermissionPro';

// export const useAuthorizedMenu = (menu: MenuConfig[]) => {
//  const user = userStorage.get();
//   // @ts-ignore
//  const JodAdmin = user.data.role === 1 ? true : false
//   const currentRole = useCurrentRole();
//   // const [currentPermissions, checKHasPermission] = useCurrentPermission();
//   const [currentUserFeatures, checkHasFeatures] = useCurrentFeature();

//   const assignedLocation = useSelector(assignedLocationSelector);

//   // const canDisplay = useCallback(
//   //   (item: Omit<MenuConfig, 'subMenu' | 'icon'>) => {
//   //     if (!currentRole || !currentPermissions?.length) return false;
//   //     const { roles, permissions } = item;

//   //     if (!roles && !permissions) return true;

//   //     if (!roles?.length && !permissions?.length) return false;

//   //     const isValidRole = roles?.length ? roles.includes(currentRole) : true;
//   //     const isValidPermission = permissions?.length ? checKHasPermission(permissions) : true;

//   //     return isValidRole && isValidPermission;
//   //   },
//   //   [checKHasPermission, currentPermissions?.length, currentRole],
//   // );

//   return useMemo(() => {
//     if (!menu || menu.length === 0) return [];

//     const result: MenuConfig[] = [];

//     for (let index = 0; index < menu.length; index++) {
//       let menuItem = menu[index];

//       // if (menuItem.permissions === 0) {
//       //   result.push(menuItem);
//       // }

//       // if (menuItem.permissions && menuItem.permissions > 0 && menuItem.permissions !== 3) {
//       //   userPermission(menuItem.permissions) && result.push(menuItem);
//       // }

//       // if (menuItem.subMenu) {
//       //   menuItem.subMenu = menuItem.subMenu.filter((menu: any) => userPermission(menu.permissions) || menu.permissions === 0)
//       //   menuItem.subMenu.length > 0 && result.push(menuItem)
//       // }

//       // if (
//       //   menuItem.enableEnv &&
//       //   !menuItem.enableEnv.includes(ENV.ENV as 'DEV' | 'STAGING' | 'PROD')
//       // ) {
//       //   continue;
//       // }

//       // if (
//       //   currentRole === Role.LocationManager &&
//       //   assignedLocation?.location_manager_can_create_job === 0 &&
//       //   menuItem.path === PATH_NAME.JOB
//       // ) {
//       //   menuItem.subMenu = undefined;
//       // }

//       // if (canDisplay(menuItem)) {
//       //   if (menuItem.subMenu?.length) {
//       //     menuItem = {
//       //       ...menuItem,
//       //       subMenu: menuItem.subMenu?.filter((item) => canDisplay(item)),
//       //     };
//       //   }
//       //   result.push(menuItem);
//       // }
//       if (!menuItem.features || menuItem.features.length === 0) {
//         result.push(menuItem);
//         continue;
//       }

//       const hasFeature = menuItem.features.some((feature) =>
//         currentUserFeatures?.includes(feature)
//       );

//       if (hasFeature) {
//         result.push(menuItem);
//       }

//       if (menuItem.subMenu) {
//         menuItem.subMenu = menuItem.subMenu.filter((subMenuItem) => {
//           const hasSubFeature = subMenuItem.features !== undefined &&
//             subMenuItem.features.some((feature) =>
//               currentUserFeatures?.includes(feature)
//             );
      
//           return hasSubFeature || subMenuItem.features === undefined;
//         });
      
//         if (menuItem.subMenu.length > 0) {
//           result.push(menuItem);
//         }
//       }
//     }

//     // return JodAdmin ? menu : result;
//     return result;
//   }, [menu, currentUserFeatures]);
// };

export const useAuthorizedMenu = (menu: MenuConfig[]) => {
  const [currentUserFeatures, checkHasFeatures] = useCurrentFeature();
  const [currentUserPermissions, checkHasPermission] = useCurrentPermission();
  const currentRoleId = useCurrentRoleId();

  const canDisplay = useCallback(
    (item: Omit<MenuConfig, 'subMenu' | 'icon'>) => {
      if (!currentUserFeatures?.length && !currentUserPermissions?.length) return false;

      const { features, roleId, permissions } = item;

      const hasValidRole = roleId
        ? (typeof currentRoleId === 'number' && roleId.includes(currentRoleId))
        : true;

      if (!features && !permissions) return hasValidRole;

      const isValidFeature = features?.length ? checkHasFeatures(features) : true;

      const isValidPermission = permissions?.length ? checkHasPermission(permissions) : true;

      return isValidFeature && isValidPermission && hasValidRole;
    },
    [checkHasFeatures, checkHasPermission, currentRoleId, currentUserFeatures?.length, currentUserPermissions?.length]
  );

  return useMemo(() => {
    if (!menu.length) return [];
    const result: MenuConfig[] = [];

    for (let index = 0; index < menu.length; index++) {
      let menuItem = menu[index];

      if (canDisplay(menuItem)) {
        if (menuItem.subMenu?.length) {
          menuItem = {
            ...menuItem,
            subMenu: menuItem.subMenu.filter((item) => canDisplay(item)),
          };
        }
        result.push(menuItem);
      }
    }

    return result;
  }, [menu, canDisplay]);
};