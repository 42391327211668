import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { currentRoleSelector } from 'store/modules/auth';

import { Role } from 'models/user';

export const useCheckAllowedRole = (requiredRoles: Role | Role[]) => {
  const role = useSelector(currentRoleSelector);
  const isValid = useMemo(() => {
    if (!role) return false;
    if (requiredRoles instanceof Array) {
      return requiredRoles.includes(role);
    } else {
      return role === requiredRoles;
    }
  }, [requiredRoles, role]);
  return isValid;
  return false;
};
