import { useMemo } from 'react';

// import { Role } from 'models/user';

// import { useCurrentRole } from 'hooks/components/auth';

import { RouteConfig, privateRoutes } from './routes';
import { useUserFirstLoginStatus } from 'hooks/components/auth/useUserFirstLoginStatus';
import { PATH_NAME } from 'configs';
import { useCurrentRoleId } from 'pro/auth/useCurrentRoleId';
import { useCurrentPermission } from 'pro/hooks/useCurrentPermissionPro';

export const useAuthorizedRoutes = () => {
  const isFirstLogin = useUserFirstLoginStatus();
  const currentUserRoleId = useCurrentRoleId();
  const [, checkHasPermission] = useCurrentPermission();

  const authorizedRoutes = useMemo<RouteConfig[]>(() => {
    const routes: RouteConfig[] = [];
    // if (!currentRole) return routes;
    for (let currRoute of privateRoutes) {

      const isValidRole = currRoute.roleId
        ? currRoute.roleId.includes(currentUserRoleId!)
        : true;

      const hasRequiredPermissions = currRoute.permissions
        ? checkHasPermission(currRoute.permissions)
        : true;

      if (!isValidRole || !hasRequiredPermissions) {
        continue;
      }
      
      if (isFirstLogin && currRoute.path !== PATH_NAME.AUTH_OTP) {
        continue;
      }

      routes.push(currRoute);
    }
    return routes;
  }, [isFirstLogin, currentUserRoleId, checkHasPermission]);

  return authorizedRoutes;
};
