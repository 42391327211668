import { JobShiftSerializer, JobShiftUserId, JobTemplateSerializer } from '../utils/serializer/jobs';
import { config } from '../config/config'
import { getPermission } from '../config/user';
import axios from 'axios';
import { authStorage } from 'configs/browser-storage';
import clevertap from 'clevertap-web-sdk';
import { flattenJob, readyForCT } from 'pro/utils/functions'
// import { DATE_TIME_BE } from 'constant';
// import { Dayjs } from 'dayjs';

// create new job

export const SubmitNewJob = async (job: object) => {

  const newJob: any = JobShiftUserId(job);

  try {
    const response = await axios.post(`${config.API_URL}/portal/jobs-management/jobs/`, newJob, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(3)?.action
        'X-Action': 'create-job'
      }
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Job Post (Web)", readyForCT(flattenedData));
    return response.data;
  } catch (err) {
    throw err;
  }

};

// get all jobs created filtered by date and oultet
export const ShowAllJobs = async (date: Date, outlets: Array<number>) => {

  // filter
  const month: number = new Date(date).getMonth() + 1;
  const year: number = new Date(date).getFullYear();
  const outlet: string = outlets.toString();

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/jobs/?month=0${month}&year=${year}&location=${outlet}&expand=location,shifts__shift_invites__user,shifts__shift_user_shift__shift_user_attendance_shift_user,shifts__shift_user_shift__user`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(1)?.action
        'X-Action': 'list-jobs'
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// update job and shift selected
export const UpdateJob = async (job: object) => {

  const newJob: any = JobShiftUserId(job);

  try {
    const response = await axios.put(`${config.API_URL}/portal/jobs-management/jobs/${newJob.id}/`, newJob, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(4)?.action
        'X-Action': 'update-job'
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Job Post (Web)", readyForCT(flattenedData));
    return response.data;
  } catch (err) {
    throw err;
  }

};

// get detail for selected shift
export const GetJob = async (id: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/jobs/${id}/?expand=location,users,type,shifts__shift_invites__user,shifts__shift_user_shift__shift_user_attendance_shift_user,shifts__shift_user_shift__user`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(2)?.action
        'X-Action': 'show-job-detail'
      },
    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// remove selected shift
export const DeleteJobShift = async (id: number) => {

  try {
    const response = await axios.delete(`${config.API_URL}/portal/jobs-management/shifts/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(18)?.action
        'X-Action': 'delete-job'
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Delete shift (Web)", flattenedData)

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const DownloadJobReports = async (dateRange: [Date | null, Date | null] | null, outletId?: number, reportIds?: Array<number> | null, columns?: Array<String> | null) => {
  try {
    const [startDate, endDate] = dateRange || [];
    const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] + ' 00:00:00' : null;
    const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] + ' 23:59:59' : null;

    const response = await axios.get(`${config.API_URL}/portal/jobs-management/shifts/download-job-report/`, {
      params: {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        outlet_id: outletId,
        report_ids: reportIds && reportIds.length > 0 ? reportIds.join(',') : 'all',
        columns: columns && columns.length > 0 ? columns.join(',') : 'all'
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(48)?.action
        'X-Action': 'list-shifts'
      },

    });
    return response.data;
  } catch (err) {
    throw err;
  }
}

export const GetJobReports = async (dateRange: [Date | null, Date | null] | null, outletId?: number) => {
  try {
    const [startDate, endDate] = dateRange || [];
    const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] + ' 00:00:00' : null;
    const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] + ' 23:59:59' : null;

    const response = await axios.get(`${config.API_URL}/portal/jobs-management/shifts/job-report/`, {
      params: {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        outlet_id: outletId,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(48)?.action
        'X-Action': 'list-shifts'
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

// get all job certificates
export const GetJobCertificates = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/certificates/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// get all job types
export const GetJobTypes = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/job-type/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};


// show all users with filter
export const GetUserFilter = async (type: string) => {

  try {
    const response = await axios.get(`${config.API_URL}/users/?roles=${type}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const AcceptUserApplicant = async (shiftId: number, userId: any) => {

  try {
    const response = await axios.post(`${config.API_URL}/portal/jobs-management/shifts/${shiftId}/accept-application/`, userId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(44)?.action
        'X-Action': 'accept-application'
      },

    });
    clevertap.event.push("Accept Applicant (Web)", {
      shiftId: shiftId,
      userId: userId
    })

    return response.data;
  } catch (err) {
    throw err;
  }

}

export const RejectUserApplicant = async (shiftId: number, userId: any) => {

  try {
    const response = await axios.post(`${config.API_URL}/portal/jobs-management/shifts/${shiftId}/reject-application/`, userId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(44)?.action
        'X-Action': 'accept-application'
      },

    });
    clevertap.event.push("Reject Applicant (Web)", {
      shiftId: shiftId,
      userId: userId
    })
    return response.data;
  } catch (err) {
    throw err;
  }

}


export const ClockInQr = async (shiftId: number, userId: number, isOtp: boolean = false) => {

  try {
    const response = await axios.get(`${config.API_URL}/attendance/${shiftId}/create-clock-in-payload/${userId}/`, {
      params: {
        'is_otp': isOtp
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(42)?.action
        'X-Action': 'create-clock-in-payload'
      },

    });
    clevertap.event.push("Clock in (Web)", {
      shiftId: shiftId,
      userId: userId
    })
    return response.data;
  } catch (err) {
    throw err;
  }

}

export const ClockInConfirm = async (shiftId: number, userId: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/attendance/${shiftId}/create-clock-in-payload/${userId}/confirm/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(42)?.action
        'X-Action': 'create-clock-in-payload'
      },

    });
    clevertap.event.push("Clock in confirm (Web)", {
      shiftId: shiftId,
      userId: userId
    })
    return response.data;
  } catch (err) {
    throw err;
  }

}

export const ClockOutQr = async (shiftId: number, userId: number, isOtp: boolean = false, clockIn: string, clockOut: string, breakTime: number, rating: number, feedback: string) => {

  try {
    const response = await axios.get(`${config.API_URL}/attendance/${shiftId}/create-clock-out-payload/${userId}/`, {
      params: {
        'is_otp': isOtp,
        'clock_in': clockIn,
        'clock_out': clockOut,
        'break_time': breakTime,
        'rating': rating,
        'feedback': feedback
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(46)?.action
        'X-Action': 'create-clock-out-payload'
      },

    });
    clevertap.event.push("Clock out (Web)", {
      shiftId: shiftId,
      userId: userId
    })
    return response.data;
  } catch (err) {
    throw err;
  }

}

export const ClockOutConfirm = async (shiftId: number, userId: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/attendance/${shiftId}/create-clock-out-payload/${userId}/confirm/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(46)?.action
        'X-Action': 'create-clock-out-payload'
      },

    });
    clevertap.event.push("Clock out confirm (Web)", {
      shiftId: shiftId,
      userId: userId
    })
    return response.data;
  } catch (err) {
    throw err;
  }

}

// create new job template
export const SubmitNewJobTemplate = async (template: object) => {

  const newTemplate: any = JobTemplateSerializer(template);

  try {
    const response = await axios.post(`${config.API_URL}/portal/jobs-management/job-template/`, newTemplate, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'create-job-template'
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Job template (Web)", readyForCT(flattenedData));
    return response.data;
  } catch (err) {
    throw err;
  }

};

export const ListClockInAndClockOuts = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/attendance/clock-in-or-out/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'create-clock-in-payload'
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

}

export const ClockInConfirmLocation = async (shift_id: number, user_id: number) => {

  const payload = {};

  try {
    const response = await axios.post(`${config.API_URL}/attendance/${shift_id}/create-clock-in-payload/${user_id}/confirm/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'create-clock-in-payload'
      },

    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const ClockOutConfirmLocation = async (shift_id: number, user_id: number) => {
  const payload = {};
  try {
    const response = await axios.post(`${config.API_URL}/attendance/${shift_id}/create-clock-out-payload/${user_id}/confirm/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'create-clock-out-payload'
      },

    });
    return response.data;
  } catch (err) {
    throw err;
  }
};


// get all jobs template 
export const ShowAllJobTemplate = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/job-template/?expand=job_type,certificates`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'job-template-list'
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// get detail for selected job template
export const GetJobTemplate = async (id: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/job-template/${id}/?expand=certificates`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'view-job-template'
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// update job template
export const UpdateJobTemplate = async (template: object) => {

  const newTemplate: any = JobTemplateSerializer(template);

  try {
    const response = await axios.put(`${config.API_URL}/portal/jobs-management/job-template/${newTemplate?.id}/`, newTemplate, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'update-job-template'
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Job template (Web)", readyForCT(flattenedData));

    return response.data;
  } catch (err) {
    throw err;
  }

};

// remove selected job template
export const DeleteJobTemplate = async (id: number) => {

  try {
    const response = await axios.delete(`${config.API_URL}/portal/jobs-management/job-template/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Delete Job template (Web)", flattenedData)

    return response.data;
  } catch (err) {
    throw err;
  }

};

// create new job template
export const SubmitNewShiftTemplate = async (template: object) => {

  const newTemplate: any = JobShiftSerializer(template);

  try {
    const response = await axios.post(`${config.API_URL}/portal/jobs-management/shift_template/`, newTemplate, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'create-shift-template'
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New shift template (Web)", readyForCT(flattenedData));

    return response.data;
  } catch (err) {
    throw err;
  }

};

// get all jobs template 
export const ShowAllShiftTemplate = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/shift_template/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'shift-templates-list'
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// get detail for selected job template
export const GetShiftTemplate = async (id: number) => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/shift_template/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'view-shift-template'
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

// update job template
export const UpdateShiftTemplate = async (template: object) => {

  const newTemplate: any = JobShiftSerializer(template);

  try {
    const response = await axios.put(`${config.API_URL}/portal/jobs-management/shift_template/${newTemplate?.id}/`, newTemplate, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'update-shift-template'
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update shift template (Web)", readyForCT(flattenedData));

    return response.data;
  } catch (err) {
    throw err;
  }

};

// remove selected job template
export const DeleteShiftTemplate = async (id: number) => {

  try {
    const response = await axios.delete(`${config.API_URL}/portal/jobs-management/shift_template/${id}/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
      },

    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Delete Shift template (Web)", flattenedData)
    return response.data;
  } catch (err) {
    throw err;
  }

};

export const ShowAllClockInOut = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/location/clock-in-or-out/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': getPermission(10)?.action
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const ShowAllShiftSwap = async () => {

  try {
    const response = await axios.get(`${config.API_URL}/portal/jobs-management/shifts/swap-request/`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        // 'X-Action': getPermission(48)?.action
        'X-Action': 'swap-request-list'
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const ShiftSwapAcceptReject = async (shiftSwap: any) => {

  try {
    const response = await axios.post(`${config.API_URL}/portal/jobs-management/shifts/swap-accept-or-reject/`, shiftSwap, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': getPermission(44)?.action
      },

    });

    return response.data;
  } catch (err) {
    throw err;
  }

};

export const OtpClockIn = async (otp: string, shift_id: number, user_id: number) => {

  const payload = { 'otp': otp, 'shift_id': shift_id, 'user_id': user_id };

  try {
    const response = await axios.post(`${config.API_URL}/attendance/otp-clock-in/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'clock-in'
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }

};

export const OtpClockOut = async (otp: string, shift_id: number, user_id: number) => {

  const payload = { 'otp': otp, 'shift_id': shift_id, 'user_id': user_id };

  try {
    const response = await axios.post(`${config.API_URL}/attendance/otp-clock-out/`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'clock-out'
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }

};
