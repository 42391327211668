// GIG
import { httpClient } from 'apis';
import i18next from 'i18next';
import { DataResponse, ListAxiosResponse, Payload } from 'types';
import { parseToFormData } from 'utils';

import { CompanyOption, ICompany } from 'models/company';

import { CompanyFilterData } from 'components/molecules/CompanyFilter';
import { Sorter } from 'components/organisms/Table';

import { concatParams, mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';
// Pro
import axios, { AxiosResponse } from 'axios';
import { config } from '../pro/config/config';
import { authStorage } from 'configs/browser-storage';
import clevertap from 'clevertap-web-sdk';
import { flattenJob, readyForCT } from 'pro/utils/functions'

interface CompanyFormData {
  [key: string]: any;
}

export const ListAllCompanies = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/company`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'list-companies'
      },
    });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const GetMyCompany = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/company/my-company`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'show-company-detail'
      },
    });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const CreateCompany = async (company: CompanyFormData) => {
  try {
    const formData = new FormData();

    // Append non-file fields to formData
    for (const key in company) {
      if (company.hasOwnProperty(key)) {
        formData.append(key, company[key]);
      }
    }

    const response = await axios.post(`${config.API_URL}/portal/jobs-management/company/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'create-company'
      },
    });
    const flattenedData = flattenJob(response.data);
    clevertap.event.push("New Company (Web)", readyForCT(flattenedData))
    return response.data;
  } catch (err) {
    throw err;
  }
};

// export const PartialUpdateCompany = async (companyId: number, updates: object) => {
//   try {
//     const response = await axios.patch(`${config.API_URL}/company/${companyId}/`, updates, {
//       headers: {
//         // 'Content-Type': 'application/json',
//         'Authorization': `Bearer ${authStorage.value?.accessToken}`,
//       },
//     });
//     const flattenedData = flattenJob(response.data);
//     clevertap.event.push("Update Company (Web)", flattenedData)
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const PartialUpdateCompany = async (companyId: number, updates: CompanyFormData) => {
  try {
    const formData = new FormData();
    for (const key in updates) {
      if (updates.hasOwnProperty(key)) {
        formData.append(key, updates[key]);
      }
    }
    const response = await axios.patch(`${config.API_URL}/company/${companyId}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${authStorage.value?.accessToken}`,
        'X-Action': 'update-company'
      },
    });

    const flattenedData = flattenJob(response.data);
    clevertap.event.push("Update Company (Web)", readyForCT(flattenedData))
    return response.data;
  } catch (err) {
    throw err;
  }
};







// GIG
const entity = 'company';

const basePath = `portal/${entity}` as const;

const companyPayloadScheme = {
  name: 'name',
  address: 'address',
  registration_no: 'businessNumber',
  min_jod_credit_limit: 'minimumCredit',
  company_logo_filename: 'logo',
  contract_service_filename: 'contract',
};

export const companyApi = {
  async list(
    payload: Payload<
      null,
      null,
      { sort?: Sorter | null; search?: string | null } & Partial<CompanyFilterData>
    >,
  ): Promise<DataResponse<ICompany[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, recent, companyStatus, minCredit, maxCredit } = query || {};

      const filterTypes = concatParams(recent ? 'recent' : '', companyStatus ?? '');

      const res: ListAxiosResponse<ICompany> = await httpClient.get(`${basePath}/index`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            filter_types: filterTypes,
            min_available_credit: minCredit,
            max_available_credit: maxCredit,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async summary(): Promise<
    DataResponse<{ totalActive: number; totalDisable: number; total: number }>
  > {
    try {
      const res: AxiosResponse<{
        data: {
          total: number;
          total_enable: number;
          total_disable: number;
        };
        message: string;
      }> = await httpClient.get(`${basePath}/summary`);
      const {
        data: {
          data: { total, total_enable: totalActive, total_disable: totalDisable },
        },
      } = res;
      return {
        status: true,
        data: {
          totalActive,
          totalDisable,
          total,
        },
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async create(
    payload: Payload<{
      name: string;
      address: string;
      businessNumber: string;
      minimumCredit: number;
      logo?: FileList;
      contract?: FileList;
      banner?: File;
    }>,
  ): Promise<DataResponse<null>> {
    try {
      const {
        body: { name, address, businessNumber, minimumCredit, logo, contract, banner },
      } = payload;
      const formData = parseToFormData({
        name: name,
        address: address,
        registration_no: businessNumber,
        min_jod_credit_limit: minimumCredit.toString(),
        company_logo_filename: logo,
        contract_service_filename: contract,
        banner_filename: banner,
      });

      const res: AxiosResponse<{ message: string; data: ICompany }> = await httpClient.post(
        `${basePath}/store`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.createdCompanyUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(companyPayloadScheme, description),
        message,
      });
    }
  },
  async update(
    payload: Payload<
      {
        name?: string;
        address?: string;
        businessNumber?: string;
        minimumCredit?: number;
        logo?: FileList;
        contract?: FileList;
        banner?: File;
      },
      { id: string | number }
    >,
  ): Promise<DataResponse<null>> {
    try {
      const {
        body: { name, address, businessNumber, minimumCredit, logo, contract, banner },
        params: { id },
      } = payload;
      const formData = parseToFormData({
        name: name,
        address: address,
        registration_no: businessNumber,
        min_jod_credit_limit: minimumCredit?.toString(),
        company_logo_filename: logo,
        contract_service_filename: contract,
        banner_filename: banner,
      });
      const res: AxiosResponse<{ message: string; data: ICompany }> = await httpClient.post(
        `${basePath}/edit/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.updatedCompanyUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(companyPayloadScheme, description),
        message,
      });
    }
  },
  async disable(payload: Payload<null, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: ICompany }> = await httpClient.delete(
        `${basePath}/disable/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.disableCompanyUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async enable(payload: Payload<null, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: ICompany }> = await httpClient.put(
        `${basePath}/enable/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.enableCompanyUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async getDetail(
    payload: Payload<null, { id: string | number }>,
  ): Promise<DataResponse<ICompany>> {
    try {
      const {
        params: { id },
      } = payload;

      const res: AxiosResponse<{ data: ICompany; message: string }> = await httpClient.get(
        `${basePath}/show/${id}`,
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        message,
        data,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async getCompanyOptions(): Promise<DataResponse<CompanyOption>> {
    try {
      const res: AxiosResponse<{ message: string; data: CompanyOption }> = await httpClient.get(
        `${basePath}/option`,
      );

      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getCompanyHasHQOptions(): Promise<DataResponse<CompanyOption>> {
    try {
      const res: AxiosResponse<{ message: string; data: CompanyOption }> = await httpClient.get(
        `${basePath}/option`,
        {
          params: {
            has_hq: 1,
          },
        },
      );

      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async updateStatus(id: string, action: 'enable' | 'disable'): Promise<DataResponse<null>> {
    if (action === 'enable') {
      return companyApi.enable({ params: { id } });
    }
    return companyApi.disable({ params: { id } });
  },
  async updateBanner(
    payload: Payload<
      {
        banner?: File;
      },
      { id: string | number }
    >,
  ): Promise<DataResponse<ICompany>> {
    try {
      const {
        body: { banner },
        params: { id },
      } = payload;
      if (!banner) {
        throw new Error(i18next.t('message.bannerCompanyHasNotBeenUploaded'));
      }
      const formData = parseToFormData({
        banner_filename: banner,
      });
      const res: AxiosResponse<{ message: string; data: ICompany }> = await httpClient.post(
        `${basePath}/${id}/update-banner`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.uploadBannerCompanyUnsuccess'), description } =
        error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(companyPayloadScheme, description),
        message,
      });
    }
  },
  async exportServiceContract(id: number | string): Promise<DataResponse<any>> {
    try {
      const res = await httpClient.get(`${basePath}/download-contract-service-file/${id}`, {
        responseType: 'blob',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportListCompanies(
    payload: Payload<null, null, { search?: string | null } & Partial<CompanyFilterData>>,
  ): Promise<DataResponse<ICompany[]>> {
    try {
      const { pagination, query } = payload;
      const { search, recent, companyStatus, minCredit, maxCredit } = query || {};
      const concatFilter = concatParams(recent ? 'recent' : '', companyStatus ?? '');
      const filterTypes = concatFilter !== '' ? concatFilter : null;

      const res = await httpClient.get(`${basePath}/export`, {
        params: mapQueryToParams({ search }, pagination, {
          filter_types: filterTypes,
          min_available_credit: minCredit,
          max_available_credit: maxCredit,
        }),
        responseType: 'arraybuffer',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type CompanyApi = typeof companyApi;
