import { FC, useMemo, useState, useEffect } from 'react';
import {
  FaArrowDown,
  FaCircle,
  FaClockRotateLeft,
  FaFire,
  FaUserTie,
  FaXmark,
} from 'react-icons/fa6';
import IconButton from './IconButton';
import { ListClockInAndClockOuts, ShiftSwapAcceptReject, ShowAllShiftSwap } from 'pro/api/jobs';
import { FailModal, SuccessModal } from './Modal';
import { ApproveRejectLeaves, ShowAllUserLeaves } from 'pro/api/user_leaves';
import { ClockInOutSerializer } from 'pro/utils/serializer/jobs';
import ClockInOutItem from './ClockInOutItem';

type FilterType = 'Clock-in' | 'Clock-out' | 'None'

interface SwapRequestProp {
  setOpenApproval: Function;
}

const SwapRequest: FC<SwapRequestProp> = ({ setOpenApproval }) => {
  const [isApproval, setIsApproval] = useState<boolean>(true);
  const [approvalSwap, setApprovalSwap] = useState<Array<object>>([]);
  const [approvalLeave, setApprovalLeave] = useState<Array<object>>([]);
  const [approvalName, setApprovalName] = useState<string>('');
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openFail, setOpenFail] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<FilterType>('None');
  const filterTypes: FilterType[] = ['Clock-in', 'Clock-out', 'None'];
  const dateOpt: object = { month: 'short', day: 'numeric', year: 'numeric' };
  const [clockInOuts, setClockInOuts] = useState<Array<object>>([]);
  const [filteredClockInOuts, setFilteredClockInOuts] = useState<Array<object>>([])

  useMemo(() => {
    ShowAllShiftSwap()
      .then((res: any) =>
        setApprovalSwap(
          res?.results.filter((swap: any) => !swap.rejected_datetime && !swap.approved_datetime),
        ),
      )
      .catch((err: any) => { });

    ShowAllUserLeaves()
      .then((res: any) => setApprovalLeave(res?.filter((leaves: any) => leaves.status === 1)))
      .catch((err: any) => { });
  }, [openSuccess, openFail]);

  const swapAcceptReject = (decision: any) => {
    ShiftSwapAcceptReject(decision)
      .then(() => setOpenSuccess(true))
      .catch(() => setOpenFail(true));
  };

  const leaveAcceptReject = (id: any, status: any) => {
    ApproveRejectLeaves(id, { status: status })
      .then(() => setOpenSuccess(true))
      .catch(() => setOpenFail(true));
  };

  useEffect(() => {
    getClockInOutList();
  },[])

  const handleFilterChange = () => {
    const currentIndex = filterTypes.indexOf(filterType);
    const nextIndex = (currentIndex + 1) % filterTypes.length;
    setFilterType(filterTypes[nextIndex]);

  };

  useEffect(() => {
    const filteredData = clockInOuts.filter((item: any) => {
      if (filterType === 'Clock-in') {
        return item.status === 'Not Started' || item.status === 'On Time' || item.status === 'Late'; 
      } else if (filterType === 'Clock-out') {
        return item.status === 'Needs Clock Out';
      } else {
        return true;
      }
    });
    setFilteredClockInOuts(filteredData);
  }, [filterType, clockInOuts]);

  const getClockInOutList = () => {
    ListClockInAndClockOuts().then((res: any) => setClockInOuts(ClockInOutSerializer(res)))
    .catch(() => setOpenFail(true));
  }

  const refreshData = () => {
    getClockInOutList();
  } 
  
  return (
    <div className="flex fixed bg-black bg-opacity-40 inset-x-0 inset-y-0 flex-row-reverse z-10">
      <div className="flex flex-col bg-vWhite w-full lg:w-1/4 justify-between">
        <div className="flex flex-col w-full p-8 h-full overflow-auto">
          <div className="flex justify-end text-lg">
            <IconButton textColor="text-vGray" onClick={() => setOpenApproval(false)}>
              <FaXmark />
            </IconButton>
          </div>
          <div className="flex flex-col space-y-8">
            <div className="flex">
              <button
                className={`flex w-full border-b-2 justify-center pt-6 rounded-t-md ease-in duration-150 hover:bg-vLightGray ${isApproval ? 'text-vOrange border-vOrange font-bold border-b-2' : 'text-vGray'}`}
                onClick={() => setIsApproval(true)}
              >
                <div className="flex space-x-2 pb-4 align-center">
                  <FaFire className="text-lg" />
                  <p className="text-base">APPROVALS</p>
                  <div className="bg-vLightOrange px-3 rounded-full">
                    {(approvalSwap.length > 0 || approvalLeave.length > 0) && (
                      <p>{approvalSwap.length + approvalLeave.length}</p>
                    )}
                  </div>
                </div>
              </button>
              <button
                className={`flex w-full border-b-2 justify-center pt-6 rounded-t-md ease-in duration-150 hover:bg-vLightGray ${!isApproval ? 'text-vOrange border-vOrange font-bold border-b-2' : 'text-vGray'}`}
                onClick={() => setIsApproval(false)}
              >
                <div className="flex space-x-2 pb-4 align-center">
                  <FaClockRotateLeft className="text-lg" />
                  <p className="text-base">CLOCK IN / OUT</p>
                </div>
              </button>
            </div>
            {isApproval ? (
              <div className="flex flex-col space-y-4">
                {/* <div className='flex space-x-2 justify-end text-vGray text-sm'>  
                  <button className='flex rounded-full shadow-md ease-in duration-150 hover:shadow-slate-500'>
                    <p className='py-1 px-2'>Approvals</p>
                  </button>
                </div> */}
                {approvalSwap.length > 0 &&
                  approvalSwap.map((swap: any) => (
                    <div
                      key={swap.id}
                      className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vBlue"
                    >
                      <div className="flex w-full justify-between">
                        <p className="text-vBlue font-bold">Shift Swap</p>
                        <div className="flex space-x-4 text-vGray text-xs">
                          <p>
                            {new Date(swap.selected_date).toLocaleDateString('default', dateOpt)}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full justify-between">
                        {/* <div className='flex space-x-4 align-center'>
                        <FaUserTie className='text-2xl'/>
                        <p>{swap.requestee_data.display_name}</p>
                      </div> */}
                        <div className="flex flex-col space-y-2 text-xs">
                          <div className="flex space-x-4 align-center">
                            <FaUserTie className="text-2xl" />
                            <p>{swap.requestor_data.display_name}</p>
                          </div>
                          <FaArrowDown className="w-6" />
                          <div className="flex space-x-4 align-center">
                            <FaUserTie className="text-2xl" />
                            <p>{swap.requestee_data.display_name}</p>
                          </div>
                        </div>
                        <div className="flex align-center space-x-4">
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vBlue bg-vLightBlue w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                swapAcceptReject({
                                  shift_request: swap.id,
                                  decision: 'approve',
                                });
                                setApprovalName(`${swap.job_data.title} Approved`);
                              }}
                            >
                              <p>Approve</p>
                            </button>
                          </div>
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vPink bg-vLightPink w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                swapAcceptReject({
                                  shift_request: swap.id,
                                  decision: 'reject',
                                });
                                setApprovalName(`${swap.job_data.title} Rejected`);
                              }}
                            >
                              <p>Reject</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {approvalLeave.length > 0 &&
                  approvalLeave.map((leave: any) => (
                    <div
                      key={leave.id}
                      className="flex flex-col w-full space-y-4 p-4 rounded-lg border-l-4 shadow-md border-vGreen"
                    >
                      <div className="flex w-full justify-between">
                        <p className="text-vGreen font-bold">Leave Request</p>
                        <div className="flex space-x-4 text-vGray text-xs">
                          <p>
                            {new Date(leave.leave_start_date).toLocaleDateString(
                              'default',
                              dateOpt,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full justify-between">
                        <div className="flex space-x-4 align-center text-xs">
                          <FaUserTie className="text-2xl" />
                          <p>{leave.user_name}</p>
                        </div>
                        {/* <div className='flex flex-col space-y-2'>
                        <div className='flex space-x-4 align-center'>
                          <FaUserTie className='text-2xl'/>
                          <p>{swap.requestee_data.display_name}</p>
                        </div>
                        <div className='flex space-x-4 align-center'>
                          <FaUserTie className='text-2xl'/>
                          <p>{swap.requestor_data.display_name}</p>
                        </div>
                      </div> */}
                        <div className="flex align-center space-x-4">
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vBlue bg-vLightBlue w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                leaveAcceptReject(leave.id, '2');
                                setApprovalName(`${leave.reason} Approved`);
                              }}
                            >
                              <p>Approve</p>
                            </button>
                          </div>
                          <div className="flex h-fit w-full text-xs">
                            <button
                              className="py-1 px-3 text-center rounded-lg text-vPink bg-vLightPink w-full shadow-md ease-in duration-150 hover:shadow-slate-500"
                              onClick={() => {
                                leaveAcceptReject(leave.id, '3');
                                setApprovalName(`${leave.reason} Rejected`);
                              }}
                            >
                              <p>Reject</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              ) : (
              <div className='flex flex-col space-y-4'>
                <div className='flex space-x-2 justify-end text-vGray text-sm'>  
                  <button className='flex rounded-full shadow-md ease-in duration-150 hover:shadow-slate-500' onClick={handleFilterChange}>
                    <p className='py-1 px-2'>{filterType}</p>
                  </button>
                  <button className="flex rounded-full shadow-md ease-in duration-150 hover:shadow-slate-500">
                    <p className="py-1 px-2">Select All</p>
                  </button>
                </div>
                {(filteredClockInOuts.map((item, index) => {return (
                  <ClockInOutItem key={index} index={index} type="location" data={item} refresh={refreshData}/>
                )}))
                }
              </div>
            )}
          </div>
        </div>
      </div>
      {openFail && (
        <FailModal
          confirm={() => setOpenFail((curr: boolean) => !curr)}
          cancel={() => setOpenFail((curr: boolean) => !curr)}
          title="Uh-oh!"
          message="We encounter some problem."
        />
      )}
      {openSuccess && (
        <SuccessModal
          confirm={() => setOpenSuccess((curr: boolean) => !curr)}
          title="Approval Complete!"
          message={`${approvalName}`}
        />
      )}
    </div>
  );
};

export default SwapRequest;
