import { PATH_NAME } from 'configs';
import { EnglishTranslation } from 'locales';

import { Role } from 'models/user';

import {
  // BillingIcon,
  // BuildingIcon,
  // ConfigurationIcon,
  // CreditIcon,
  // EducationIcon,
  // FeedIcon,
  // FolderIcon,
  // PaymentIcon,
  // PlaceIcon,
  // ResumeIcon,
  // SearchIcon,
  // TooltipIcon,
  // UserIcon,
  // UserPermissionIcon,
  // WriteIcon,
  ScheduleIcon,
  WorkforceIcon,
  OutletIcon,
  PayrollIcon,
  AdminIcon,
  ReportIcon,
  SettingIcon,
} from 'assets/icons';
// import { userPermission } from 'pro/config/user';
import { Features, SystemFeatures } from 'pro/types/features';
import { Permissions, SystemPermissions } from 'pro/types/permissions';

export type MenuConfig = {
  title: keyof EnglishTranslation;
  titleInMobile?: keyof EnglishTranslation;
  path: string;
  icon: React.FC;
  roles?: Role[];
  roleId?: number[];
  permissions?: SystemPermissions[];
  features?: SystemFeatures[];
  subMenu?: Array<Omit<MenuConfig, 'subMenu' | 'icon'>>;
  enableEnv?: Array<'DEV' | 'STAGING' | 'PROD'>;
  canReplaceParent?: boolean;
};

export const navMenuConfig: MenuConfig[] = [
  {
    title: 'title.dashboard',
    path: PATH_NAME.DASHBOARD,
    icon: AdminIcon,
    features: [
      Features.REPORTING_AND_ANALYTICS,
    ]
  },
  {
    title: 'title.companies',
    path: PATH_NAME.COMPANIES,
    icon: OutletIcon,
    features: [
      Features.COMPANY_MANAGEMENT,
    ],
    roleId: [1],
  },
  {
    title: 'title.company',
    path: PATH_NAME.COMPANY,
    icon: OutletIcon,
    features: [
      Features.COMPANY_MANAGEMENT,
    ],
  },
  {
    title: 'title.outlet',
    path: PATH_NAME.OUTLET,
    icon: OutletIcon,
    features: [
      Features.OUTLET_MANAGEMENT,
    ],
  },
  {
    title: 'title.workforce',
    path: PATH_NAME.WORKFORCE_MANAGER,
    icon: WorkforceIcon,
    features: [
      Features.WORKFORCE_MANAGEMENT,
    ],
    subMenu: [
      {
        title: 'title.workforce.manager',
        path: PATH_NAME.WORKFORCE_MANAGER,
        permissions: [
          Permissions.LIST_WORKFORCE_MANAGER
        ],
      },
      {
        title: 'title.workforce.worker',
        path: PATH_NAME.WORKFORCE_WORKER,
        permissions: [
          Permissions.LIST_WORKFORCE_WORKER
        ],
      },
    ],
  },
  {
    title: 'title.schedule',
    path: PATH_NAME.SCHEDULE,
    icon: ScheduleIcon,
    features: [
      Features.ATTENDANCE_MANAGEMENT,
    ],
    subMenu: [
      {
        title: 'title.schedule.job',
        path: PATH_NAME.SCHEDULE,
        permissions: [
          Permissions.LIST_ALL_JOBS
        ],
      },
      {
        title: 'title.schedule.job.table',
        path: PATH_NAME.JOBS,
        permissions: [
          Permissions.LIST_ALL_JOBS
        ],
      },
      {
        title: 'title.schedule.job.swap.request',
        path: PATH_NAME.SWAP_REQUEST,
        permissions: [
          Permissions.LIST_SHIFT
        ],
      },
      {
        title: 'title.schedule.job.leave.tracker',
        path: PATH_NAME.LEAVE_TRACKER,
        permissions: [
          Permissions.LEAVES_LIST
        ],
      },
      {
        title: 'title.schedule.job.template',
        path: PATH_NAME.JOB_TEMPLATE,
        permissions: [
          Permissions.LIST_JOB_TEMPLATE
        ],
      },
      // {
      //   title: 'title.schedule.job.paymentrules',
      //   path: PATH_NAME.PAYMENT_RULES,
      //   permissions: 0,
      // },
    ],
  },
  {
    title: 'title.payroll',
    path: PATH_NAME.PAYROLL,
    icon: PayrollIcon,
    features: [
      Features.PAYROLL_MANAGEMENT,
    ],
  },
  {
    title: 'title.reports',
    path: PATH_NAME.REPORT,
    icon: ReportIcon,
    features: [
      Features.REPORTING_AND_ANALYTICS,
    ],
    subMenu: [
      {
        title: 'title.reports.job',
        path: PATH_NAME.REPORT_JOB,
        permissions: [
          Permissions.LIST_SHIFT
        ],
      },
      // {
      //   title: 'title.reports.outlet',
      //   path: PATH_NAME.REPORT_OUTLET,
      //   permissions: 0,
      // },
      // {
      //   title: 'title.reports.payroll',
      //   path: PATH_NAME.REPORT_PAYROLL,
      //   permissions: 0,
      // },
    ],
  },
  {
    title: 'title.setting',
    path: PATH_NAME.ACCOUNT_SETTINGS,
    icon: AdminIcon,
    features: [
      Features.ROLE_MANAGEMENT,
      Features.SYSTEM_CONFIGURATION,
    ],
    subMenu: [
      {
        title: 'title.setting.account.settings',
        path: PATH_NAME.ACCOUNT_SETTINGS,
        features: [
          Features.ROLE_MANAGEMENT,
        ],
      },
      // {
      //   title: 'title.setting.billing.summary',
      //   path: PATH_NAME.BILLING_SUMMARY,
      //   permissions: 0,
      // },
      // {
      //   title: 'title.setting.payment.settings',
      //   path: PATH_NAME.PAYMENT_SETTINGS,
      //   permissions: 0
      // },
      {
        title: 'title.setting.subscription',
        path: PATH_NAME.SUBSCRIPTION,
        features: [
          Features.COMPANY_MANAGEMENT,
        ],
      },
    ],
  },
];
