export enum Features {
  USER_MANAGEMENT = 1,
  ROLE_MANAGEMENT = 2,
  OUTLET_MANAGEMENT = 3,
  WORKFORCE_MANAGEMENT = 4,
  JOB_MANAGEMENT = 5,
  ATTENDANCE_MANAGEMENT = 6,
  TASK_MANAGEMENT = 7,
  REPORTING_AND_ANALYTICS = 8,
  PAYROLL_MANAGEMENT = 9,
  NOTIFICATION_AND_ALERTS = 10,
  SYSTEM_CONFIGURATION = 11,
  COMPANY_MANAGEMENT = 12,
}

export type SystemFeatures = Features;