import { FC, useEffect, useRef, useState } from "react";
import { Box, Text, VStack, HStack, Button, Image, Avatar, IconButton, Input } from "@chakra-ui/react";
import { ClockInConfirmLocation, ClockInQr, ClockOutConfirmLocation, ClockOutQr, OtpClockIn, OtpClockOut } from "pro/api/jobs";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa6';
import { FailModal, SuccessModal } from "./Modal";
import ClockInForm from "pro/forms/schedule/ClockInForm";
import ClockOutForm from "pro/forms/schedule/ClockOutForm";
import { ClockOutInterface } from "pro/utils/interface/jobs";
import UserJobDetail from "pro/forms/schedule/UserJobDetail";
import UserJobCost from "pro/forms/schedule/UserJobCost";


type ClockInOutType = 'location' | 'normal';

interface ClockInOutItemProps {
  index: number,
  type: ClockInOutType,
  data: any,
  refresh: Function
}

const ClockInOutItem: FC<ClockInOutItemProps> = (props) => {
  const bgColors = ["#EDF0F7","#FFECD4","#DFF1FF"];
  const titleColors = ["#A5B2CD", "#FF9240", "#30B6FF"];
  const [showQR, setShowQR] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>('');
  const [openFailQrCode, setOpenFailQrCode] = useState<boolean>(false);
  const [openFailOtp, setOpenFailOtp] = useState<boolean>(false);
  const [openSuccessOtp, setOpenSuccessOtp] = useState<boolean>(false);
  const [openSuccessClockOutOtp, setOpenSuccessClockOutOtp] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [clockout, setClockout] = useState<any>(structuredClone(ClockOutInterface));
  const [otpMode, setOtpMode] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<string>('');
  const [jobDetails, isJobDetails] = useState<boolean>(false);

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);

    const utcDate = new Date(date.getTime() + (8 * 60 * 60 * 1000));

    return utcDate.toISOString().slice(0, 19).replace('T', ' ');
  };

  const convertToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const isFirstRender = useRef(true);

  const handleClockInOut = () => {
    setShowQR(true);
    clockQr();
  }

  const confirmLocationClockInOut = () => {
    if (props.data?.status === 'Not Started') {
      console.info("LOCBASE");
      ClockInConfirmLocation(props.data?.shift_id, props.data?.user_id)
        .then((res: any) => {
          if (res?.success) {
            props.refresh();
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
    if (props.data?.status === 'Clocked In' || props.data?.status === 'Ready to Clock Out' || props.data?.status === 'Needs Clock Out') {
      const formattedStartTime = formatDateTime(clockout.start_time);
      const formattedEndTime = formatDateTime(clockout.end_time);
      const formattedBreakTime = convertToMinutes(clockout.break_time);

      ClockOutConfirmLocation(props.data?.shift_id, props.data?.user_id /*, otpMode, formattedStartTime, formattedEndTime, formattedBreakTime, clockout.rating, clockout.feedback*/)
        .then((res: any) => {
          if (res?.success) {
            props.refresh()
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
  }

  const toggleDetails = () => {
    setOpenDetails(!openDetails);
  }

  const toggleMode = () => {
    setOtpMode(!otpMode);
  }

  const clockQr = () => {
    if (props.data?.status === 'Not Started') {
      console.info('TRIGGER QR');
      console.info(otpMode);
      ClockInQr(props.data?.shift_id, props.data?.user_id, otpMode)
        .then((res: any) => {
          if (res?.success) {
            setQrCode(res?.data.qrcode_url);
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
    if (props.data?.status === 'Clocked In' || props.data?.status === 'Ready to Clock Out' || props.data?.status === 'Needs Clock Out') {
      const formattedStartTime = formatDateTime(clockout.start_time);
      const formattedEndTime = formatDateTime(clockout.end_time);
      const formattedBreakTime = convertToMinutes(clockout.break_time);

      ClockOutQr(props.data?.shift_id, props.data?.user_id, otpMode, formattedStartTime, formattedEndTime, formattedBreakTime, clockout.rating, clockout.feedback)
        .then((res: any) => {
          if (res?.success) {
            setQrCode(res?.data.qrcode_url);
          } else {
            setOpenFailQrCode(true);
          }
        })
        .catch(() => setOpenFailQrCode(true));
    }
  };

  useEffect(() => {
    if(isFirstRender.current) {
      isFirstRender.current = false
    } else {
      clockQr();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[otpMode])

  const handleSubmitOtp = () => {
    if (props.data?.status === 'Not Started') {
      OtpClockIn(otpValue, props.data?.shift_id, props.data.user_id).then((res:any) => {
        setOpenSuccessOtp(true);
        props.refresh()
      })
      .catch(() => setOpenFailOtp(true));
    }
    if (props.data?.status === 'Clocked In' || props.data?.status === 'Ready to Clock Out' || props.data?.status === 'Needs Clock Out') {
      OtpClockOut(otpValue, props.data?.shift_id, props.data.user_id).then((res:any) => {
        setOpenSuccessClockOutOtp(true);
        props.refresh()
      })
      .catch(() => setOpenFailOtp(true));
    }
  };

  const backgroundColor = props.data?.status === 'Not Started' ? "#FF9240" : 
                        props.data?.status === 'Needs Clock Out' ? "#30B6FF" : 
                        props.data?.status === 'Completed' ? "#43CB12" : 
                        "#FF9240"; 

  return (
    <VStack
      w="full"
      spacing={4}
      p={4}
      rounded="lg"
      shadow="md"
      bg={bgColors[props.index % bgColors.length]}
      align="stretch"
    >
      <HStack justifyContent={'space-between'}>
        <Text color={titleColors[props.index % titleColors.length]} fontWeight="bold" fontSize={20}>
          Job / Shift Title
        </Text>
        <IconButton
          rounded="full"
          background={"#FF9240"}
          fill="white"
          aria-label={openDetails ? 'Collapse' : 'Expand'}
          icon={openDetails ? <FaChevronUp color="white" /> : <FaChevronDown color="white" />}
          onClick={toggleDetails}
          variant="link"
          width="40px"
          height="40px"
        />
      </HStack>
      <VStack
        w="full"
        rounded="xl"
        shadow="md"
        bg="white"
        align="stretch"
        paddingTop={8} paddingLeft={8} paddingRight={8} paddingBottom={8}
      >
        <VStack w="full" >
          <HStack w='full' justify="space-between">
            <HStack>
              <Avatar
                boxSize="60px"
                color="white"
                bg="tango.300"
                rounded="lg"
                name={props.data?.full_name}
                src={props.data?.avatar}
              />
              <VStack alignItems="start">
                <Text fontWeight="bold">{props.data?.full_name}</Text>
                <Text color="#999999">{props.data?.job_title}</Text>
              </VStack>
            </HStack>
            <Box rounded='md' backgroundColor={backgroundColor} p={2}>
              <Text textColor='white'>{props.data?.status}</Text>
            </Box>
          </HStack>
        </VStack>

        {openDetails && (
          props.data?.status !== 'Completed' ? (
            props.data?.status === 'Ready to Clock In' || props.data?.status === 'Not Started' ? (
              <ClockInForm data={props.data} />
            ) : (
              <UserJobDetail
                job={props.data?.job}
                shifts={props.data?.shift}
                userStatus={props.data?.status === 'Completed'
                  ? 'Clocked Out'
                  : props.data?.status}
                clockout={clockout}
                setClockout={setClockout}
                editable={openDetails}
              />
              // <ClockOutForm data={props.data} clockout={clockout} setClockout={setClockout} />
            )
          ) : (
            null
            // <div className="flex w-full pb-2">
            //   <button
            //     className={`flex w-full justify-center py-2 border-b-2 ease-in duration-150 hover:bg-vLightGray rounded-t-md ${!jobDetails ? 'text-vOrange border-vOrange font-bold' : 'text-vGray border-vGray'}`}
            //     onClick={() => isJobDetails(false)}
            //   >
            //     <p>Details</p>
            //   </button>
            //   <button
            //     className={`flex w-full justify-center py-2 border-b-2 ease-in duration-150 hover:bg-vLightGray rounded-t-md ${jobDetails ? 'text-vOrange border-vOrange font-bold' : 'text-vGray border-vGray'}`}
            //     onClick={() => isJobDetails(true)}
            //   >
            //     <p>Cost Breakdown</p>
            //   </button>
            // </div>
          )
        )}

        {openDetails && props.data?.status === 'Completed' && !jobDetails && (
          <UserJobDetail
            job={props.data?.job}
            shifts={props.data?.shift}
            userStatus={props.data?.status === 'Completed'
              ? 'Clocked Out'
              : props.data?.status}
            clockout={clockout}
            setClockout={setClockout}
            editable={!openDetails}
          />
        )}
        {/* {openDetails && props.data?.status === 'Completed' && jobDetails && (
          <UserJobCost />
        )} */}

      </VStack>

      {openDetails && props.data?.status !== 'Completed' && (
        <>
          {showQR ? (
            <VStack 
              w="full"
              spacing={4}
              p={8}
              rounded="lg"
              shadow="md"
              bg="white"
              align="center"
            >
              <Text fontWeight="bold">{otpMode ? props.data?.status !== 'Needs Clock Out' ? 'Clock In OTP': 'Clock Out OTP' : props.data?.status !== 'Needs Clock Out' ? 'Clock In QR' : 'Clock Out QR'}</Text>
              {otpMode ? (
                <VStack w='full' alignItems="end" gap={0}>
                  <Input
                    placeholder="Enter OTP"
                    value={otpValue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    maxLength={6}
                  />
                  <Button onClick={clockQr} variant="link" color="#FF9240">Resend Otp</Button>
                </VStack>
              ) : (
                <Image src={qrCode} maxHeight={"150px"} maxWidth={"150px"} />
              )}
              <Button w="full" textColor="#FFB771" backgroundColor="#FFECD4" textTransform="uppercase" onClick={handleSubmitOtp}>Confirm</Button>
              <Button w="full" backgroundColor="#FF9240" onClick={toggleMode}>{otpMode ? 'Switch to QR' : 'Switch to OTP'}</Button>
            </VStack>
          ) : (
            <Button backgroundColor="#FF9240" textTransform="uppercase" disabled={props.data?.status !== 'Not Started' && props.data?.status !== 'Clocked In' && props.data?.status !== 'Needs Clock Out'} onClick={props.data?.is_scan_user_location_qr ? confirmLocationClockInOut : handleClockInOut}>
              {props.data?.is_scan_user_location_qr ? 'Confirm' : props.data?.status !== 'Needs Clock Out' ? 'Clock In' : 'Clock Out'}
            </Button>
          )}
        </>
      )}
      {openSuccessOtp && (
        <SuccessModal
          confirm={() => setOpenSuccessOtp((curr: boolean) => !curr)}
          title="Otp Clock In"
          message={`Otp Clock In Completed Successfully`}
        />
      )}
      {openSuccessClockOutOtp && (
        <SuccessModal
          confirm={() => setOpenSuccessClockOutOtp((curr: boolean) => !curr)}
          title="Otp Clock Out"
          message={`Otp Clock Out Completed Successfully`}
        />
      )}
      {openFailQrCode && (
        <FailModal
          confirm={() => setOpenFailQrCode((curr: boolean) => !curr)}
          cancel={() => setOpenFailQrCode((curr: boolean) => !curr)}
          title="Wait!"
          message="No upcoming Job Shift yet"
        />
      )}
      {openFailOtp && (
        <FailModal
          confirm={() => setOpenFailOtp((curr: boolean) => !curr)}
          cancel={() => setOpenFailOtp((curr: boolean) => !curr)}
          title="Wait!"
          message="There is an issue with the otp. Please try again"
        />
      )}
    </VStack>
  );
}

export default ClockInOutItem;
