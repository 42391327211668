export const flattenJob = (data: any, parentKey: string = '', result: any = {}) => {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      flattenJob(item, `${parentKey}_${index}`, result);
    });
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((key) => {
      const value = data[key];

      // Check for date objects
      if (value instanceof Date) {
        result[parentKey ? `${parentKey}_${key}` : key] = value; // Directly use the Date instance
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        // Check if it is a date-time object
        if (value && Object.values(value).every(val => typeof val === 'string')) {
          // Reconstruct date string from the object
          const dateString = Object.values(value).join('');
          if (isValidDateString(dateString)) {
            result[parentKey ? `${parentKey}_${key}` : key] = new Date(dateString);
          } else {
            flattenJob(value, parentKey ? `${parentKey}_${key}` : key, result);
          }
        } else {
          flattenJob(value, parentKey ? `${parentKey}_${key}` : key, result);
        }
      } else if (typeof value === 'string' && isValidDateString(value)) {
        result[parentKey ? `${parentKey}_${key}` : key] = new Date(value);
      } else {
        result[parentKey ? `${parentKey}_${key}` : key] = value;
      }
    });
  } else {
    if (data) {
      result[parentKey] = data;
    }
  }
  return result;
};


const removeKeys = ['creation_fields_', 'editable_fields_'];

export function readyForCT(data: any): any {
  if (Array.isArray(data)) {
    return null; // or return an empty array based on your requirements
  }

  if (typeof data !== 'object' || data === null) {
    return data; // Return the value if it's not an object
  }

  const result: any = {};

  Object.keys(data).forEach((key) => {
    const containsRemoveKey = removeKeys.some(substring => key.includes(substring));

    // If the key should be removed or if the value is an array, skip it
    if (containsRemoveKey || Array.isArray(data[key])) {
      return; // Skip this key
    }

    // Convert Date objects to ISO strings
    if (data[key] instanceof Date) {
      result[key] = data[key].toISOString(); // Convert Date to ISO string
    } else {
      result[key] = readyForCT(data[key]); // Recursive call
    }
  });

  return result;
}




export function isValidDateString(dateString: string): boolean {
  // Regular expression to match ISO 8601 date format
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/;

  // Check if the string matches the ISO 8601 format
  if (!iso8601Regex.test(dateString)) {
    return false;
  }

  // Convert to date and check if it's valid
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}
