import { FireWithBadgeIcon } from 'assets/icons';
import { useDeviceMode } from 'hooks/common';
import { FC, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { headerTitleSelector, jodAdminSelector } from 'store/modules/config';
import { DeviceMode } from 'types';
import CompanySelector from 'pro/components/CompanySelector';
import Notifications from './Notifications';
import SwapRequest from './Approvals';
import { FaBars } from 'react-icons/fa6';

interface HeaderProps {
  onClick: () => void;
}

const Header: FC<HeaderProps> = ({ onClick }) => {
  const device = useDeviceMode();
  const { headerTitle } = useSelector(headerTitleSelector);
  const JodAdmin = useSelector(jodAdminSelector);

  const isMobile = device === DeviceMode.Mobile;

  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [openApproval, setOpenApproval] = useState<boolean>(false);

  return (
    <>
      <div className="flex items-center justify-between w-full px-5 lg:px-8 lg:bg-[#f6f7fa] lg:py-5">
        <div className="flex items-center gap-1">
          <h3 className="text-sm font-bold uppercase text-black md:text-xl lg:text-2xl">
            {headerTitle}
          </h3>
        </div>

        <div className="flex items-center gap-1">
          <div className="flex lg:hidden">{JodAdmin && <CompanySelector />}</div>
          <div
            className={`flex items-center ${isMobile ? 'w-[27px] h-[27px]' : 'w-[41px] h-[41px]'}`}
          >
            <FireWithBadgeIcon
              className="cursor-pointer"
              onClick={() => setOpenApproval((curr: any) => !curr)}
            />
          </div>
          <div
            className="flex xl:hidden border border-gray-300 p-[6px] md:p-3 rounded-[5px] md:rounded-lg md:bg-white"
            onClick={onClick}
          >
            <FaBars className="text-vGray" />
          </div>
        </div>

        {openNotification && <Notifications setOpenNotification={setOpenNotification} />}
        {openApproval && <SwapRequest setOpenApproval={setOpenApproval} />}
      </div>
      {isMobile && JodAdmin && (
        <div className="flex items-center justify-between w-full px-5 bg-[#f6f7fa]">
          <CompanySelector />
        </div>
      )}
    </>
  );
};

export default memo(Header);
