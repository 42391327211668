export enum Permissions {
  // Jobs
  LIST_ALL_JOBS = 1,
  VIEW_JOB_DETAIL = 2,
  CREATE_JOB = 3,
  UPDATE_JOB = 4,
  DELETE_JOB = 5,

  // Job Template
  LIST_JOB_TEMPLATE = 59,
  CREATE_JOB_TEMPLATE = 60,
  UPDATE_JOB_TEMPLATE = 61,
  VIEW_JOB_TEMPLATE = 62,

  // Companies
  LIST_COMPANIES = 6,
  VIEW_COMPANY_DETAIL = 7,
  CREATE_COMPANY = 8,
  UPDATE_COMPANY = 9,

  // Locations/Outlet
  LIST_LOCATIONS = 10,
  VIEW_LOCATION_DEATAIL = 11,
  CREATE_LOCATION = 12,
  UPDATE_LOCATION = 13,
  DELETE_LOCATION = 14,
  ENABLE_LOCATION = 15,
  UPLOAD_LOCATION = 16,
  DOWNLOAD_LOCATION = 17,
  CREATE_UPDATE_COST_BREAKDOWN = 53,

  // Shift
  LIST_SHIFT = 48,
  APPLY_TO_SHIFT = 44,
  DELETE_SHIFT = 18,
  SHIFT_TEMPLATES_LIST = 55,
  SHIFT_TEMPLATES_CREATE = 56,
  SHIFT_TEMPLATES_UPDATE = 57,
  SHIFT_TEMPLATES_VIEW = 58,

  // Swap Request
  LIST_SWAP_REQUEST = 68,
  CREATE_SWAP_REQUEST = 69,
  UPDATE_SWAP_REQUEST = 70,
  VIEW_SWAP_REQUEST = 71,

  // Rules
  LIST_AUTOMATED_RULES = 19,
  CREATE_AUTOMATED_RULES = 20,
  UPDATE_AUTOMATED_RULES = 21,
  DELETE_AUTOMATED_RULES = 22,

  // Payroll
  LIST_PAYROLL = 72,
  CREATE_PAYROLL = 73,
  UPDATE_PAYROLL = 74,
  VIEW_PAYROLL = 75,

  // Pay Template
  LIST_PAY_TEMPLATE = 23,
  CREATE_PAY_TEMPLATE = 24,
  UPDATE_PAY_TEMPLATE = 25,
  DELETE_PAY_TEMPLATE = 26,

  // Workforce Manager
  LIST_WORKFORCE_MANAGER = 27,
  CREATE_WORKFORCE_MANAGER = 28,
  UPDATE_WORKFORCE_MANAGER = 29,
  DELETE_WORKFORCE_MANAGER = 30,
  UPLOAD_WORKFORCE_MANAGER = 32,
  DOWNLOAD_WORKFORCE_MANAGER = 33,

  // Workforce Worker
  LIST_WORKFORCE_WORKER = 34,
  CREATE_WORKFORCE_WORKER = 35,
  RETRIEVE_WORKFORCE_WORKER = 36,
  UPDATE_WORKFORCE_WORKER = 37,
  DELETE_WORKFORCE_WORKER = 38,
  UPLOAD_WORKFORCE_WORKER = 39,
  DOWNLOAD_WORKFORCE_WORKER = 40,

  // Attendance
  CLOCK_IN = 41,
  CREATE_CLOCK_IN = 42,
  ACCEPT_APPLICATION = 43,
  SCAN_AUTO_ATTENDANCE = 45,
  CREATE_CLOCK_OUT = 46,
  CLOCK_OUT = 47,

  // Leaves
  LEAVES_LIST = 63,
  LEAVES_CREATE = 64,
  LEAVES_UPDATE = 65,
  LEAVES_VIEW = 66,
  LEAVES_APPROVE_REJECT = 67,

  // Report
  LIST_REPORT = 76,
  VIEW_REPORT = 77,

  // Dashboard
  DASHBOARD = 78,

  // Permission
  UPDATE_PERMISSION = 49,

  // User Role
  UPDATE_ROLE = 50,
  DELETE_ROLE = 51,
  CREATE_ROLE = 52,
  LIST_ROLES = 54,

}

export type SystemPermissions = Permissions;