import { RootState } from 'store/root-reducer';

export const currentUserSelector = (rootState: RootState) => rootState.auth.user;
export const currentCompanySelector = (rootState: RootState) => rootState.company.company;
export const currentLocationsSelector = (rootState: RootState) => rootState.location.locations;
export const currentJobTypesSelector = (rootState: RootState) => rootState.keptQuery.jobTypes;
export const currentManagerTypesSelector = (rootState: RootState) => rootState.keptQuery.managerTypes;
export const currentRegionsSelector = (rootState: RootState) => rootState.keptQuery.regions;
export const currentManagersSelector = (rootState: RootState) => rootState.manager.managers;
export const currentWorkersSelector = (rootState: RootState) => rootState.applicant.workers;
export const currentWorkersPaginationSelector = (rootState: RootState) => rootState.applicant.pagination;



export const currentUserPermissionSelector = (rootState: RootState) => rootState.auth.permissions;
export const currentUserPermissionProSelector = (rootState: RootState) => rootState.auth.permission_pro;
export const currentUserFeatureSelector = (rootState: RootState) => rootState.auth.user?.features;
export const authSelector = (rootState: RootState) => rootState.auth;
export const rememberSelector = (rootState: RootState) => rootState.auth.isRemember;
export const currentRoleSelector = (rootState: RootState) =>
  currentUserSelector(rootState)?.role.type;
export const currentRoleIdSelector = (rootState: RootState) =>
  currentUserSelector(rootState)?.role_id;
export const isTwoFaVerifiedSelector = (rootState: RootState) => rootState.auth.user?.tfa_verified;
